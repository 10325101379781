


















































































































































































import FormInputField from "@/components/form-components/FormInputField.vue";
import djlMultiselect from "@/components/form-components/djlMultiselect.vue";
import VForm from "@/components/VForm.vue";
import { Component, Watch } from "vue-property-decorator";
import {
  divisions,
  Gender,
  genders,
  nameUsages,
  NameUse,
  NewCustomer,
} from "@/models/user";
import { Account } from "@/interfaces/Account";
import accessControl from "@/api/access_control";
import { mixins } from "vue-class-component";
import Page from "@/components/layouts/Page.vue";
import TheHeader from "@/components/TheHeader.vue";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { Route } from "vue-router";
import {
  DepartmentOptions,
  NewCustomerForm,
  SelectionOptions,
} from "@/views/usermanagement/UserManagementCreate";

@Component({
  components: {
    Page,
    TheHeader,
    VForm,
    FormInputField,
    djlMultiselect,
  },
})
@Translatable("usermanagement")
export default class UserManagementCreate extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  private formLoading = false;

  @Watch("formLoading")
  onFormLoadingChange(isLoading: boolean) {
    isLoading
      ? window.addEventListener("beforeunload", this.onBeforeUnload)
      : window.removeEventListener("beforeunload", this.onBeforeUnload);
  }

  private onBeforeUnload(e: Event): string {
    return this.translated_view_value("leave_confirmation") as string;
  }

  private beforeRouteLeave(to: Route, from: Route, next: () => any) {
    if (this.formLoading) {
      const answer = confirm(
        this.translated_view_value("leave_confirmation") as string
      );
      if (answer) {
        next();
      }
    } else {
      next();
    }
  }

  get nameUseOptions(): SelectionOptions<NameUse>[] {
    return nameUsages.map((e) => {
      return {
        value: e,
        label: this.model_human_attribute_value("name_use", e),
      } as SelectionOptions<NameUse>;
    });
  }

  get genderOptions(): SelectionOptions<Gender>[] {
    return genders.map((e) => {
      return {
        value: e,
        label: this.model_human_attribute_value("gender", e),
      } as SelectionOptions<Gender>;
    });
  }

  get departments(): DepartmentOptions[] {
    return divisions.map((dep) => {
      return {
        key: dep,
        value: this.model_human_attribute_value("division", dep),
      } as DepartmentOptions;
    });
  }

  private input: NewCustomerForm = {
    department: null,
    jobtitle: "",
    nameUse: this.nameUseOptions[0],
    initials: "",
    firstname: "",
    middlename: "",
    lastname: "",
    partner: { firstname: "", middlename: "", lastname: "" },
    email: "",
    gender: this.genderOptions[0],
    phoneNumber: "",
    account: null,
  };

  private messages = {};

  private accountList: Account[] = [];

  mounted() {
    accessControl.accounts
      .accountsWithRole("user_management")
      .then(({ data }) => {
        this.accountList = data;
      });
  }

  private cancelClicked(event: Event): void {
    this.$router.push({ name: "usermanagement-link" });
  }

  private createAccount(event: Event): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.formLoading = true;
      const {
        nameUse,
        firstname,
        lastname,
        account,
        gender,
        department,
        initials,
        jobtitle,
        middlename,
        partner,
        phoneNumber,
        email,
      } = this.input;
      let newCustomer: NewCustomer = {
        gender: gender.value,
        accountId: account?.id || "",
        department: department?.key || null,
        nameUse: nameUse.value,
        email,
        initials,
        firstname,
        middlename,
        lastname,
        jobtitle,
        partner,
        phoneNumber,
      };

      if (newCustomer.nameUse === "own") {
        delete newCustomer.partner;
      }
      accessControl.usermanagement
        .create(newCustomer)
        .then((response) => {
          this.$snotify.success("Medegebruiker succesvol aangemaakt.");
          this.formLoading = false;
          this.$router.push({
            name: "usermanagement-detail-new",
            query: { contactId: response.data.profiles[0].id },
          });

          resolve(response);
        })
        .catch((error) => {
          this.$snotify.error("Er is iets fout gegaan.");
          reject(error);
        })
        .finally(() => (this.formLoading = false));
    });
  }
}
